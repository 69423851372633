import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../components/styles/search.css";
import { Button, Form, Modal } from "react-bootstrap";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import "../../components/styles/index.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

import {
  Autocomplete,
  Box,
  CircularProgress,
  Slider,
  TextField,
} from "@mui/material";
import FilterModal from "../../components/filterModal";
import ColumnHeader from "../../components/columnHeader";
import ContextMenu from "../../components/contextMenu";
import RowsContext from "../../components/contextMenu/rowsMenu";
import {
  AutoCompleteFields,
  CareerInfoFlagFields,
  RangeFields,
  categories,
  defaultColumns,
  fieldOptions,
  fieldsToCheck,
  searchDataInitalValues,
  selectFieldValues,
} from "../../data/searchpage/data";
import {
  getNameFromValue,
  toProperCase,
} from "../../helperFunctions/functions";
import {
  deleteSavedFormat,
  fetchingResultsForAutoComplete,
  getUserFilters,
  performSearch,
  saveFilter,
} from "../../services/searchPageApis/api";
import { masterDataTable } from "../../config";

function PersonsData() {
  //////////////////////// States //////////
  /* delete saved format configurations*/
  const [showModaldel, setShowModalDel] = useState(false);
  const handleRemoveSavedFormat = () => {
    setShowModalDel(true);
  };
  //0/

  const [activeSearchTab, setActiveSearchTab] = useState("newSearchForm");
  const [deleteFormatButton, setDeleteFormatButton] = useState(false);
  const [SavedformatId, setSavedFormatId] = useState("");

  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  /// category adding fields
  const [categorySelectedOptions, setCategorySelectedOptions] = useState({});

  /// State for rendering the saved filters
  const [savingFilters, setSavingfilters] = useState(false);

  /// Save filters Configurations
  const [finallSearchData, setFinalSearchData] = useState({});
  const [showSaveFilterButton, setShowSaveFilterButton] = useState(false);
  const [isSaveFilterModalOpen, setIsSaveFilterModalOpen] = useState(false);
  const [filterName, setFilterName] = useState("");
  ///// Getting User Filters
  const [filters, setFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [tabledata, setTableData] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  // const [changes, setChanges] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState("d-none");
  ////
  const [searchFields, setSearchFields] = useState([]);

  //   const [OrderModal, setOrderModal] = useState(false);
  const [fetchTimeout, setFetchTimeout] = useState(null);

  useEffect(() => {
    const fetchfilters = async () => {
      try {
        const filteredFilters = await getUserFilters();
        const companyInfoFilters = filteredFilters.filter((filter) => {
          const parsedFilter = JSON.parse(filter.filters);
          return parsedFilter.tableName === masterDataTable;
        });
        setFilters(companyInfoFilters);
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchfilters();
  }, [savingFilters]);

  const handleDeleteFormat = async () => {
    try {
      const message = await deleteSavedFormat(SavedformatId);
      toast.success(message);
      setShowModalDel(false);
      setSavingfilters(true);
      setSavedFormatId("");
      setDeleteFormatButton(false);
      setCategorySelectedOptions({});
      setSearchFields([]);
      setSearchData(searchDataInitalValues);
      setShow("d-none");
    } catch (error) {
      toast.error(error.message || "Error while removing this Format");
    } finally {
      setShowModalDel(false);
      setTimeout(() => setSavingfilters(false), 500);
    }
  };

  const handleFilterChange = async (event) => {
    const filterName = event.target.value;
    const filter = filters.find((f) => f.filtername === filterName);
    setSelectedFilter(filter);
    if (filter) {
      try {
        setSavedFormatId(filter.id);
        const filterCriteria = JSON.parse(filter.filters);
        setSearchFields([]);
        setSearchResults([]);
        const excludedFields = ["filterColumn", "order", "page", "tableName", "FilterStatments"];

        const fieldsWithValues = Object.entries(filterCriteria)
          .filter(([key, value]) => value && !excludedFields.includes(key)) // Ensure that the value is not null, undefined, or empty
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        if (fieldsWithValues["linkedin_flag_info"]) {
          const flags = fieldsWithValues["linkedin_flag_info"].split(",");
          flags.forEach((flag) => {
            fieldsWithValues[flag.toLowerCase()] = flag;
          });

          // Remove the linkedin_flag_info key from fieldsWithValues
          delete fieldsWithValues["linkedin_flag_info"];
        }
        const newSearchFields = Object.keys(fieldsWithValues);
        setSearchFields(newSearchFields);
        setCategorySelectedOptions(fieldsWithValues);
        if (filterCriteria["linkedin_flag_info"]) {
          const flags = filterCriteria["linkedin_flag_info"].split(",");

          // Convert each flag into a key-value pair and add to filterCriteria
          flags.forEach((flag) => {
            filterCriteria[flag.toLowerCase()] = flag;
          });
        }

        if (filterCriteria["date_of_birth"]) {
          const parts = filterCriteria["date_of_birth"].split("/");
          filterCriteria["day"] = "";
          filterCriteria["month"] = "";
          filterCriteria["year"] = "";
          switch (parts.length) {
            case 3:
              [
                filterCriteria["day"],
                filterCriteria["month"],
                filterCriteria["year"],
              ] = parts;
              break;
            case 2:
              if (parts[1].length === 4) {
                [filterCriteria["month"], filterCriteria["year"]] = parts;
              } else {
                [filterCriteria["day"], filterCriteria["month"]] = parts;
              }
              break;
            case 1:
              // Assuming a single part is most likely the year, but this assumption might need review
              filterCriteria["day"] = parts[0];
              break;
            default:
              console.error(
                "Unexpected date format:",
                filterCriteria["date_of_birth"]
              );
              filterCriteria["day"] = "";
              filterCriteria["month"] = "";
              filterCriteria["year"] = "";
              break;
          }
        } else {
          filterCriteria["day"] = "";
          filterCriteria["month"] = "";
          filterCriteria["year"] = "";
        }
        setSearchData(filterCriteria);
        // setSearchData(filterCriteria);
        setDeleteFormatButton(true);
      } catch (err) {
        console.log(err);
      }
    }
  };
  ////////////////////////////////////////

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    position: { x: 0, y: 0 },
    columnIndex: null,
  });

  //  table records context menu
  const [rowsMenu, setRowsMenu] = useState({
    visible: false,
    position: { x: 0, y: 0 },
  });

  const showRowsMenu = (e, place) => {
    e.preventDefault();
    document.addEventListener("mousedown", handleClickOutside, { once: true });
    setRowsMenu({
      visible: true,
      position: { x: e.pageX, y: e.pageY },
      place: place,
    });
  };
  const hideRowsMenu = () => {
    document.removeEventListener("mousedown", handleClickOutside);
    setRowsMenu({
      visible: false,
      position: { x: 0, y: 0 },
    });
  };

  const [searchdata, setSearchData] = useState(searchDataInitalValues);

  ///////////////////////////////////// Hiding and showing Columns /////////////////////////////////////////////////////
  const hideColumn = () => {
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      newColumns[contextMenu.columnIndex].visible = false;
      return newColumns;
    });
    setContextMenu({
      visible: false,
      position: { x: 0, y: 0 },
      columnIndex: null,
    });
  };

  const showColumn = (columnName) => {
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      const columnToBeVisible = newColumns.find(
        (column) => column.name === columnName
      );
      columnToBeVisible.visible = true;
      return newColumns;
    });
    setContextMenu({
      visible: false,
      position: { x: 0, y: 0 },
      columnIndex: null,
    });
  };

  const showContextMenu = (e, index) => {
    e.preventDefault();
    document.addEventListener("mousedown", handleClickOutside, { once: true });
    setContextMenu({
      visible: true,
      position: { x: e.pageX, y: e.pageY },
      columnIndex: index,
    });
  };

  const handleClickOutside = (event) => {
    const contextMenuElement = document.querySelector(".context-menu");
    if (contextMenuElement && !contextMenuElement.contains(event.target)) {
      hideContextMenu();
    }
  };

  const hideContextMenu = () => {
    document.removeEventListener("mousedown", handleClickOutside);
    setContextMenu({
      visible: false,
      position: { x: 0, y: 0 },
      columnIndex: null,
    });
  };

  //////////// Save filters Modal /.================================
  const handleOpenSaveFilterModal = () => {
    setIsSaveFilterModalOpen(true);
  };

  const handleCloseSaveFilterModal = () => {
    setIsSaveFilterModalOpen(false);
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////// Save Filters Api call
  const handleSaveFilter = async () => {
    try {
      await saveFilter(filterName, finallSearchData);
      setSavingfilters(true);
      setIsSaveFilterModalOpen(false);
      toast.success("Search Filter saved successfully");
      setFilterName("");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTimeout(() => setSavingfilters(false), 500);
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////// Draggind and Dropping Columns  ////////////

  const moveColumn = (draggedIndex, targetIndex) => {
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      const draggedColumn = newColumns[draggedIndex];
      const targetColumn = newColumns[targetIndex];
      newColumns[targetIndex] = draggedColumn;
      newColumns[draggedIndex] = targetColumn;
      return newColumns;
    });
    // setChanges(true);
  };
  ///////////////////////////////// /////////////////////////////////////////////////////Clicking on a row
  const openProfile = (ID) => {
    console.log(
      "######################################################################"
    );
    console.log(ID);
    console.log(
      "######################################################################"
    );

    window.open(`/personaldetails/${ID}`, "_blank");
    setRowsMenu({
      visible: false,
      position: { x: 0, y: 0 },
      place: null,
    });
  };

  const handleSelectRow = (index) => {
    setSelectedRow(index);
  };

  const handleSliderChange = (e) => {
    setSearchData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAutocompleteChange = (event, newValue, reason, fieldValue) => {
    if (reason === "input") {
      const inputValue = event.target.value;
      setSearchData((prevData) => ({
        ...prevData,
        [fieldValue]: inputValue.toUpperCase(),
      }));
      if (inputValue.length > 0) {
        if (fetchTimeout) clearTimeout(fetchTimeout);

        const newTimeout = setTimeout(() => {
          fetchAddressResults(inputValue.toUpperCase(), fieldValue);
        }, 1000);

        setFetchTimeout(newTimeout);
      }
    }

    // Check if the change is due to selection of an option
    if (reason === "reset" && newValue) {
      setSearchData((prevData) => ({
        ...prevData,
        [fieldValue]: newValue.toUpperCase(),
      }));
    }
  };
  /////////////////////////////////////////// Settings values from search bars ///////////////////////////////
  const handleFieldChange = (e) => {
    let newValue;

    if (e.target.name === "unc_member_relation" || e.target.value === "NaN") {
      newValue = e.target.value;
    } else {
      newValue = e.target.value.toUpperCase();
    }
    setSearchData((prevData) => {
      return { ...prevData, [e.target.name]: newValue };
    });
  };

  const executeSearch = async (page = Datapage) => {
    // Check if either order or filterColumn has a value
    if (searchdata.order || searchdata.filterColumn) {
      // If only one of them has a value, show an error toast and return
      if (!searchdata.order || !searchdata.filterColumn) {
        toast.error("Both order and Order field are required.");
        return;
      }
    }
    const SearchFields = {};
    fieldsToCheck.forEach((field) => {
      const fieldValue = searchdata[field];

      if (typeof fieldValue === "string" && fieldValue.trim() !== "") {
        SearchFields[field] = fieldValue;
      } else if (fieldValue) {
        SearchFields[field] = fieldValue;
      }
    });

    const hasNonEmptyFields = Object.keys(SearchFields).length > 0;

    if (!hasNonEmptyFields) {
      return;
    }
    setIsSearching(true);

    setLoading(true);
    const { day, month, year } = searchdata;

    let DATE_OF_BIRTH_FORMATTED = "";
    if (day && month && year) {
      DATE_OF_BIRTH_FORMATTED = `${day}/${month}/${year}`;
    } else if (day && month) {
      DATE_OF_BIRTH_FORMATTED = `${day}/${month}`;
    } else if (month && year) {
      DATE_OF_BIRTH_FORMATTED = `${month}/${year}`;
    } else if (day && year) {
      DATE_OF_BIRTH_FORMATTED = `${day}//${year}`;
    } else if (day) {
      DATE_OF_BIRTH_FORMATTED = `${day}`;
    } else if (month) {
      DATE_OF_BIRTH_FORMATTED = `/${month}/`;
    } else if (year) {
      DATE_OF_BIRTH_FORMATTED = `/${year}`;
    }
    // to get the flag fields
    let flagValues = [];
    CareerInfoFlagFields.forEach((field) => {
      if (searchdata[field] && searchdata[field] === field.toUpperCase()) {
        flagValues.push(field.toUpperCase());
      }
    });

    searchdata.linkedin_flag_info = flagValues.join(",");

    const {
      profile_link,
      contact_url_company,
      profile_title,
      profile_summary,
      skills,
      languages,
      honors,
      publications,
      certifications,
      contact_url_portfolio,
      contact_url_blog,
      contact_url_rss,
      contact_url_other,
      experience_json,
      education_json,
      ...restOfSearchData
    } = searchdata;

    const filteredData = {
      ...restOfSearchData,
      day: "",
      month: "",
      year: "",
      page: page,
      date_of_birth: DATE_OF_BIRTH_FORMATTED,
      FilterStatments: searchdata.FilterStatments,
    };
    setFinalSearchData(filteredData);
    try {
      const data = await performSearch(filteredData);
      setShowSaveFilterButton(true);
      setTableData(data.detailedData);
      setDataPage(data.pagination.current_page);
      setDataTotalPages(data.pagination.total_pages);
      setShow("d-block");
    } catch (err) {
      setShow("d-none");

      toast.error("Error while searching , Please try again");
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      executeSearch(1);
    }
  };
  /////////// remove search field button
  const RemoveButton = ({ onClick }) => (
    <div className="flex items-center justify-center ml-3">
      <button
        type="button"
        onClick={onClick} // Use passed onClick function
        className="flex items-center justify-center h-12 w-12 rounded bg-pink-100 text-red-500 text-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
      >
        <ClearOutlinedIcon></ClearOutlinedIcon>
      </button>
    </div>
  );

  ////////////////////////////// making the form dynamic ////////////////

  const handleCategoryChange = (category, value) => {
    setCategorySelectedOptions((prev) => ({ ...prev, [category]: value }));
    handleAddSearchField(category, value);
  };
  const handleAddSearchField = (category, selectedField) => {
    setSearchResults([]);

    if (!searchFields.includes(selectedField) && selectedField !== "") {
      setSearchFields([...searchFields, selectedField]);
      setCategorySelectedOptions((prev) => ({ ...prev, [category]: "" }));
    }
  };

  const handleRemoveSearchField = (field) => {
    setSearchFields(searchFields.filter((f) => f !== field));

    if (field === "date_of_birth") {
      setSearchData((prevSearchData) => ({
        ...prevSearchData,
        day: "",
        month: "",
        year: "",
      }));
    } else {
      setSearchData((prevSearchData) => ({
        ...prevSearchData,
        [field]: "",
      }));
    }
  };
  ////// functions for formatting the input values
  const renderSelectField = (fieldKey) => {
    return (
      <div key={fieldKey} className="d-flex">
        <select
          onKeyPress={handleKeyPress}
          className="appearance-menulist-button  w-full px-3 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
          style={{
            background: "#F2F3F7",
          }}
          name={fieldKey}
          value={searchdata[fieldKey] || ""}
          onChange={handleFieldChange}
        >
          {fieldOptions[fieldKey].map((option) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <RemoveButton onClick={() => handleRemoveSearchField(fieldKey)} />
      </div>
    );
  };

  ///***    Fetching results for address       */

  const fetchAddressResults = async (value, column) => {
    setSearchResults([]);

    try {
      const data = await fetchingResultsForAutoComplete(
        masterDataTable,
        value,
        column
      );

      setSearchResults(data.search_results);
    } catch (err) {
      console.log(err);
    }
  };

  /////////////////////////////////////////// Pagination ////////////////////////////////

  const [Datapage, setDataPage] = useState(1);
  const [DatatotalPages, setDataTotalPages] = useState(0);

  function handlePervData() {
    setDataPage((prevPage) => Math.max(prevPage - 1, 1));
  }
  function handleNextData() {
    setDataPage((prevPage) => Math.min(prevPage + 1, DatatotalPages));
  }

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
  };

  useEffect(() => {
    executeSearch(Datapage);
  }, [Datapage]);
  return (
    <div className="flex h-screen">
      {/* Content for the 1/4 width section */}

      <div className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 bg-white-50 p-3 overflow-y-auto">
        <div className="flex mb-4 mt-3 px-2 py-2 rounded bg-gray-100">
          <button
            onClick={() => {
              setActiveSearchTab("newSearchForm"); // Set active tab
            }}
            className={`flex-1 text-center text-sm rounded	 py-2 px-2  focus:outline-none ${activeSearchTab === "newSearchForm" ? "bg-white" : "bg-gray-100"
              }`}
          >
            New Search Form
          </button>
          <button
            onClick={() => {
              setActiveSearchTab("savedForms"); // Set active tab
            }}
            className={`flex-1 text-center text-sm rounded	 py-2 px-2 focus:outline-none ${activeSearchTab === "savedForms" ? "bg-white" : "bg-gray-100"
              }`}
          >
            Saved Forms
          </button>
        </div>
        {activeSearchTab === "savedForms" && (
          <div className="mt-4">
            <h2
              className="text-xl font-semibold text-start"
              style={{ color: "#33355A", fontSize: "16px" }}
            >
              My Form
            </h2>
            <div
              className="w-3/4 h-1 bg-teal-400 rounded"
              style={{ height: "fit-content" }}
            ></div>
            <div className="mb-6 mt-4">
              <label
                className="block text-sm font-semibold text-start text-gray-700 mb-2"
                style={{
                  color: "#6D6E85",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                }}
              >
                SELECT YOUR PREDEFINED FORM
              </label>
              <div className="relative">
                <select
                  onChange={handleFilterChange}
                  value={selectedFilter ? selectedFilter.filtername : ""}
                  className="appearance-none bg-arrow w-full px-3 py-3 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
                  style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236d6e85' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
                    backgroundPosition: "right 1rem center",
                    backgroundSize: "1.5em 1.5em",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "#F2F3F7",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  aria-label="Select a field"
                >
                  <option value="" defaultValue className="text-gray-400">
                    Select a form name
                  </option>

                  {filters.length > 0 ? (
                    filters.map((filter, index) => (
                      <option key={index} value={filter.filtername}>
                        {filter.filtername}
                      </option>
                    ))
                  ) : (
                    <option value="">No filters available</option>
                  )}
                </select>
                {deleteFormatButton && (
                  <button
                    className="absolute right-0 bottom-0 mb-2 mr-2 px-4 py-2 bg-red-500 text-white text-sm font-medium rounded hover:bg-red-600 focus:outline-none focus:bg-red-700"
                    onClick={handleRemoveSavedFormat}
                  >
                    Delete
                  </button>
                )}{" "}
                <Modal
                  show={showModaldel}
                  onHide={() => setShowModalDel(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to remove this Format?
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={handleDeleteFormat}
                      className="mb-2 mr-2 px-4 py-2 bg-red-500 text-white text-sm font-medium rounded hover:bg-red-600 focus:outline-none focus:bg-red-700"
                    >
                      Delete
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        )}
        <div className="mt-5 mb-5">
          <h2
            className="text-xl font-semibold text-start"
            style={{ color: "#33355A", fontSize: "16px" }}
          >
            Add Custom Filters
          </h2>
          <div className="w-3/4 h-1 bg-teal-400 rounded"></div>
          {rowsMenu.visible && (
            <RowsContext
              position={rowsMenu.position}
              onClose={hideRowsMenu}
              place={rowsMenu.place}
              OpenProfile={() => openProfile(rowsMenu.place.id_master)}
              voter={true}
            />
          )}
          {contextMenu.visible && (
            <ContextMenu
              position={contextMenu.position}
              hide={hideContextMenu}
              hideColumn={hideColumn}
              showColumn={showColumn}
              columns={columns}
            />
          )}
          <FilterModal
            searchdata={searchdata}
            executeSearch={executeSearch}
            setSearchData={setSearchData}
            setDataPage={setDataPage}
            datapage={Datapage}
            categories={categories}
            type="persons"
          />
        </div>
        <div className="mb-6">
          <h2
            className="text-xl font-semibold text-start"
            style={{ color: "#33355A", fontSize: "16px" }}
          >
            Commonly Used Fields
          </h2>
          <div className="w-3/4 h-1 bg-teal-400 rounded"></div>
        </div>
        {Object.entries(categories).map(([category, fields]) => (
          <div key={category} className="mb-6">
            <label
              className="block text-sm font-semibold text-start text-gray-700 mb-2"
              style={{
                color: "#6D6E85",
                fontSize: "14px",
                letterSpacing: "0.07em",
              }}
            >
              {category.toUpperCase()}
            </label>
            <div className="relative">
              <select
                onChange={(e) => handleCategoryChange(category, e.target.value)}
                value={categorySelectedOptions[category] || ""}
                className="w-full appearance-none px-3 py-3 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
                style={{
                  background: `#F2F3F7 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236d6e85' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") no-repeat`,
                  backgroundPosition: "right 1rem center",
                  backgroundSize: "1.5em 1.5em",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                }}
                aria-label="Select a field"
              >
                <option value="" defaultValue className="text-gray-400">
                  Select a Field
                </option>
                {fields.map((field) => (
                  <option
                    key={field.name}
                    value={field.value}
                    disabled={searchFields.includes(field.value)}
                  >
                    {field.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ))}{" "}
      </div>
      {/* Content for the 3/4 width section */}

      <div
        className="w-3/4 bg-gray-50 py-5 px-4 "
        style={{ height: "fit-content" }}
      >
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold text-gray-800">
            {activeSearchTab === "savedForms" ? (
              <>Search Form</>
            ) : (
              <>Create Search Form</>
            )}
          </h1>
          {showSaveFilterButton ? (
            <>
              <button
                onClick={handleOpenSaveFilterModal}
                style={{ backgroundColor: "#00CC99" }}
                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                + Save Form
              </button>
              <Modal
                show={isSaveFilterModalOpen}
                onHide={handleCloseSaveFilterModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Enter a name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formName">
                      <Form.Label className="contextMenuLabel">Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        onChange={(e) => setFilterName(e.target.value)}
                        className="modal-input"
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleCloseSaveFilterModal}
                  >
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleSaveFilter}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <div></div>
          )}
        </div>
        <div className=" bg-white rounded py-3 px-4 mt-4">
          {/* Title for the new section */}
          <h2 className="text-lg text-start font-bold text-gray-800 mb-4">
            Variation
          </h2>
          <div className="flex flex-wrap -mx-3">
            {searchFields.length > 0 &&
              searchFields.map((fieldValue, index) => (
                <div
                  className={`w-full sm:w-1/2 md:w-1/2 px-3 mb-6 ${(index + 1) % 3 === 0 ? "md:pr-3" : "md:pl-3"
                    }`}
                  key={fieldValue}
                >
                  <label
                    className="block w-full text-start text-gray-700 text-sm font-semibold mb-2 truncate"
                    style={{
                      color: "#6D6E85",
                      fontSize: "14px",
                      letterSpacing: "0.07em",
                    }}
                  >
                    {getNameFromValue(fieldValue).toUpperCase()}
                  </label>

                  {fieldValue === "date_of_birth" ? (
                    <>
                      <div className=" d-flex ">
                        <select
                          value={searchdata["day"] || ""}
                          onKeyPress={handleKeyPress}
                          className="appearance-none w-full px-3 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out  focus:text-gray-700 focus:outline-none"
                          style={{
                            background: "#F2F3F7",
                            width: "30%",
                            marginRight: "2%",
                          }}
                          name="day"
                          onChange={handleFieldChange}
                        >
                          <option>Day</option>
                          {Array.from({ length: 31 }, (_, i) =>
                            (i + 1).toString().padStart(2, "0")
                          ).map((day) => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          ))}
                        </select>
                        <select
                          value={searchdata["month"] || ""}
                          onKeyPress={handleKeyPress}
                          className="appearance-none w-full px-3 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out  focus:text-gray-700 focus:outline-none"
                          style={{
                            background: "#F2F3F7",
                            width: "30%",
                            marginRight: "2%",
                          }}
                          name="month"
                          onChange={handleFieldChange}
                        >
                          <option>Month</option>
                          {Array.from({ length: 12 }, (_, i) =>
                            (i + 1).toString().padStart(2, "0")
                          ).map((month) => (
                            <option key={month} value={month}>
                              {month}
                            </option>
                          ))}
                        </select>
                        <select
                          value={searchdata["year"] || ""}
                          onKeyPress={handleKeyPress}
                          className="appearance-none w-full px-3 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
                          style={{
                            background: "#F2F3F7",
                            width: "30%",
                          }}
                          name="year"
                          onChange={handleFieldChange}
                        >
                          <option>Year</option>
                          {Array.from(
                            { length: 121 },
                            (_, i) => new Date().getFullYear() - i
                          ).map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                        <RemoveButton
                          onClick={() => handleRemoveSearchField(fieldValue)}
                        />
                      </div>
                    </>
                  ) : AutoCompleteFields[fieldValue] ? (
                    <div className="d-flex">
                      <Autocomplete
                        id="address-autocomplete"
                        name={fieldValue}
                        options={searchResults}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            border: "none",
                          },
                        }}
                        className="appearance-none w-full  text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
                        style={{
                          background: "#F2F3F7",
                        }}
                        value={searchdata[fieldValue] || ""}
                        getOptionLabel={(option) => toProperCase(option)}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onInputChange={(event, newValue, reason) =>
                          handleAutocompleteChange(
                            event,
                            newValue,
                            reason,
                            fieldValue
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            onKeyPress={handleKeyPress}
                          />
                        )}
                      />

                      <RemoveButton
                        onClick={() => handleRemoveSearchField(fieldValue)}
                      />
                    </div>
                  ) : RangeFields[fieldValue] ? (
                    <div className="d-flex justify-content-between">
                      <Box
                        style={{
                          width: "84%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Slider
                          size="medium"
                          value={searchdata[fieldValue] || [0, 1]}
                          onChange={handleSliderChange}
                          onKeyPress={handleKeyPress}
                          valueLabelDisplay="auto"
                          aria-labelledby="input-slider"
                          sx={{
                            "& .MuiSlider-thumb": {
                              height: 24,
                              width: 24,
                              backgroundColor: "#fff",
                              border: "2px solid #eee", // Assuming a green border as seen in the image
                              // Add two lines on the thumb
                              "&:before": {
                                display: "block",
                                content: '""',
                                height: 12,
                                width: 3,
                                backgroundColor: "#00CC99",
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform:
                                  "translate(-50%, -50%) rotate(90deg)", // to make lines vertical
                                borderRadius: "1px",
                              },
                              "&:after": {
                                display: "block",
                                content: '""',
                                height: 12,
                                width: 3,
                                backgroundColor: "#00CC99",
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)", // keep lines horizontal
                                borderRadius: "1px",
                              },
                            },
                            "& .MuiSlider-track": {
                              color: "#00CC99", // This changes the color of the track
                            },
                            "& .MuiSlider-rail": {
                              color: "#ddd", // This changes the color of the rail (you can also set this to red or any other color)
                            },
                          }}
                          min={0}
                          max={1}
                          step={0.01}
                          name={fieldValue}
                        />
                      </Box>
                      <RemoveButton
                        onClick={() => handleRemoveSearchField(fieldValue)}
                      />
                    </div>
                  ) : selectFieldValues[fieldValue] ? (
                    renderSelectField(fieldValue)
                  ) : (
                    <div className="d-flex">
                      <input
                        value={searchdata[fieldValue] || ""}
                        type={
                          fieldValue === "age" || fieldValue === "hholdsize"
                            ? "number"
                            : "text"
                        }
                        onKeyPress={handleKeyPress}
                        className="appearance-none w-full px-3 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
                        style={{
                          background: "#F2F3F7",
                        }}
                        name={fieldValue}
                        onChange={handleFieldChange}
                      />
                      <RemoveButton
                        onClick={() => handleRemoveSearchField(fieldValue)}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
          <button
            onClick={() => executeSearch(1)}
            disabled={isSearching}
            className={`flex items-center justify-center px-4 py-2 rounded text-white font-bold ${isSearching
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-green-700"
              } `}
            style={{ backgroundColor: "#00CC99" }}
          >
            <span>Filter Results</span>
            <ManageSearchIcon className="ml-2" style={{ color: "white" }} />
          </button>
        </div>
        {loading ? (
          <div
            className="mt-4"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress sx={{ color: "black" }} />
          </div>
        ) : (
          <div className={` bg-white rounded py-3 px-4 ${show} mt-5`}>
            <div className="d-flex justify-content-between px-2 mb-3">
              <h2 className="text-lg text-start  py-2 font-bold text-gray-800 ">
                Search Result
              </h2>
              <div className="flex items-center gap-2">
                <select
                  className="appearance-none w-full px-3 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
                  style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236d6e85' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
                    backgroundPosition: "right 0.75rem center",
                    backgroundSize: "1.5em 1.5em",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "#F2F3F7",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  name="filterColumn"
                  onChange={(e) => {
                    setSearchData((prevData) => {
                      return { ...prevData, [e.target.name]: e.target.value };
                    });
                  }}
                  value={searchdata["filterColumn"]}
                >
                  <option value="">Choose field</option>
                  {Object.keys(categories).map((category) =>
                    categories[category]
                      .filter(
                        (field) => !CareerInfoFlagFields.includes(field.value)
                      )
                      .map((field) => (
                        <option key={field.value} value={field.value}>
                          {field.name}
                        </option>
                      ))
                  )}
                </select>
                <select
                  className="appearance-none w-full px-3 py-2 text-base font-normal bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:outline-none"
                  style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236d6e85' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
                    backgroundPosition: "right 0.75rem center",
                    backgroundSize: "1.5em 1.5em",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "#F2F3F7",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
                  }}
                  value={searchdata["order"]}
                  name="order"
                  onChange={(e) => {
                    setSearchData((prevData) => {
                      return { ...prevData, [e.target.name]: e.target.value };
                    });
                  }}
                >
                  <option value="">Choose Order</option>
                  <option value="ASC">Ascending</option>
                  <option value="DESC">Descending</option>
                </select>
              </div>{" "}
            </div>
            <div className={`table-responsive overflow-y-auto max-h-[500px] `}>
              <table className="table  table-hover">
                <thead className="sticky top-0 z-[9999]">
                  <tr className="">
                    {columns.map(
                      (column, index) =>
                        column.visible && (
                          <ColumnHeader
                            key={column.name}
                            index={index}
                            name={column.name}
                            label={column.label}
                            moveColumn={moveColumn}
                            hideColumn={hideColumn}
                            showContextMenu={showContextMenu}
                            title={column.label}
                          />
                        )
                    )}
                  </tr>
                </thead>

                <tbody className="">
                  {tabledata.length > 0 ? (
                    tabledata.map((place, i) => (
                      <tr
                        style={{ cursor: "pointer" }}
                        key={place.id_master}
                        className={`text-start py-2 px-2 ${selectedRow === i ? "selected-row" : ""
                          }`}
                        onClick={() => handleSelectRow(i)}
                        onContextMenu={(e) => {
                          if (window.innerWidth > 768) {
                            showRowsMenu(e, place);
                          }
                        }}
                        onDoubleClick={(e) => {
                          if (window.innerWidth <= 768) {
                            showRowsMenu(e, place);
                          }
                        }}
                      >
                        {columns.map((column) => {
                          if (column.visible) {
                            return (
                              <td
                                key={`${place.id_master}-${column.name}`}
                                style={{
                                  verticalAlign: "middle",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  minWidth: "200px",
                                  height: "50px",
                                }}
                                className="text-start py-2 px-2"
                              >
                                {toProperCase(place[column.name])}
                              </td>
                            );
                          }
                          return null;
                        })}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No Matching Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {tabledata.length > 0 && (
              <div>
                <div colSpan={columns.length}>
                  <div className="row">
                    <div className="col-12 flex justify-end mt-5">
                      <FaCaretLeft
                        className={`
                             text-gray-200 bg-custom-green-500 cursor-pointer h-10 w-10 p-2 rounded-lg`}
                        size={30}
                        onClick={Datapage === 1 ? null : handlePervData}
                      />
                      <span
                        className="mx-2 d-flex align-items-center
                      "
                        style={{ fontSize: "1.2rem" }}
                      >
                        {Datapage} of {DatatotalPages}
                      </span>
                      <FaCaretRight
                        className={`text-gray-200 bg-custom-green-500 
                        cursor-pointer
                         h-10 w-10 p-2 rounded-lg`}
                        size={30}
                        onClick={
                          Datapage === DatatotalPages ? null : handleNextData
                        }
                        style={{ fontSize: "1.3rem" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default PersonsData;
